import { configureStore } from '@reduxjs/toolkit';
import tenantSlice from './tenantSlice';

export const store = configureStore({
  reducer: {
    tenant: tenantSlice,
  },
});

/** @typedef {ReturnType<typeof store.getState>} RootState */

/** @typedef {typeof store.dispatch} AppDispatch */
