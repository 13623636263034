import { useSelector, useDispatch } from 'react-redux';
import { fetchTenantData } from '../store/tenantSlice';
import React from 'react';

export function useTenantData() {
  const dispatch = useDispatch();
  const tenantData = useSelector((state) => state.tenant.data);
  const status = useSelector((state) => state.tenant.status);
  const error = useSelector((state) => state.tenant.error);

  React.useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchTenantData());
    }
  }, [status, dispatch]);

  return { data: tenantData, isLoading: status === 'loading', error };
}